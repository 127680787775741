import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Constants from './../utilities/Constants';
import CardHeader from '@mui/material/CardHeader';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import ICard from '../utilities/Icard';

const Listing = () => {
    
    const [loading, setLoading] = React.useState(true);
    const [voters, setVoters] = React.useState([]);
    const [loadingData, setLoadingData] = React.useState(true);

    React.useEffect(() => {

         Constants.ourRequest = axios.CancelToken.source()

        if(Constants?.selectedConstituency === undefined) {
            Constants.navigate('/')
        }

        if(Constants.voters.length) {
            setVoters(Constants.voters)
            setLoading(false)
        } else {
            get_data(1)
        }

        return () => {
            Constants.ourRequest.cancel()
          }

    }, [])

    const get_data = async (p) => {
        let url = Constants.api.voterList.replace('{SCHOOL}', Constants?.search?.school === undefined ? '' : Constants?.search?.school)
                                        .replace('{FNAME}', Constants?.search?.firstName === undefined ? '' : Constants?.search?.firstName)
                                        .replace('{LNAME}', Constants?.search?.lastName === undefined ? '' : Constants?.search?.lastName)
                                        .replace('{MNAME}', Constants?.search?.middleName === undefined ? '' : Constants?.search?.middleName)
                                        .replace('{EPICNO}', Constants?.search?.epicNo === undefined ? '' : Constants?.search?.epicNo)
                                        .replace('{TOKEN}', Constants.selectedConstituency?.token)
                                        .replace('{PAGE}', p)
            

            await axios.get(url, {
                cancelToken: Constants.ourRequest.token,
              })
                .then(async r => {
                    Constants.voters = [...Constants.voters, ...r.data.data]
                    await setVoters(Constants.voters)
                    await setLoading(false)
                    if((r.data.data.length)) {
                        await get_data(p+1)
                    } else {
                        setLoadingData(false)
                    }
                })
                .catch(e => {
                    setVoters([])
                    console.error(e)
                    setLoading(false)
                })
    }

    return (
        <Card >
            <CardHeader title={
                    `${Constants.languages[Constants.selectedLanguage].list.ttl.pageTitle}` 
                } sx={{ textAlign: 'center' }} className='bg-brand' />
            <CardContent>
            {/* {loadingData ? <CircularProgress style={{margin: '0 auto', display: 'block'}}/> : <></>} */}
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', maxWidth: '100%' }} fullWidth>
                {!loading ? (
                    voters?.length == 0 ? <>
                        <p> No voter found</p>
                        <LoadingButton 
                            className='bg-brand'
                            variant="contained" 
                            size="small"
                            onClick={
                                () => {
                                Constants.navigate('/search')
                                }
                            }
                    >
                        <span>
                          {'< '} {Constants.languages[Constants.selectedLanguage].details.btn.back}
                        </span>
                    </LoadingButton>
                    </> : <> 
                    {
                        voters.map(e =>
                         <>
                            <ICard e={e} />
                            <Divider sx={{margin: '10px 0'}} className='bg-brand' />
                    </>)
                    }
                     {loadingData ? <Typography 
                        variant="h7" 
                        component="div" 
                        sx={{ flexGrow: 1, textAlign: 'center', cursor: 'pointer' }} 
                        display="flex" 
                        justifyContent="flex-center"
                        className="text-brand"
                        >Loading...</Typography> : <></>}
                    </>
            ) : (
                    [1,2,3,4,5,6,7,8,9,10].map(e => <>
                        <Skeleton variant="rectangular" width={'100%'} height={250} animation="wave" />
                        <Divider sx={{margin: '10px 0'}} />
                    </>
                    )
            )
            
            }
                
            </List>
        </CardContent>
    </Card>
)}

export default Listing;
