import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from './ui/Layout';
import Home from "./ui/Home";
import Search from "./ui/Search";
import Details from "./ui/Details";
import Listing from "./ui/List";
import Notfound from "./ui/Notfound";
import Redirect from "./ui/Redirect";
import Welcome from "./ui/Welcome";
// import Errors from "./ui/Error";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="search" element={<Search />} />
          <Route path="details/:id" element={<Details />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="list" element={<Listing />} />
          <Route path="redirect" element={<Redirect />} />
          <Route path="*" element={<Notfound />} />
        </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
