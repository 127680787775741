import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Constants from './../utilities/Constants';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';

// import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import ReactCaptcha from 'modern-react-captcha';
import './index.css';


const Search = () => {

    const [loading, setLoading]                           = React.useState(false);
    const [selectedConstituency, setSelectedConstituency] = React.useState(false);
    const [firstName, setFirstName]                       = React.useState('');
    const [middleName, setMiddleName]                     = React.useState('');
    const [lastName, setLastName]                         = React.useState('');
    const [epicNo, setEpicNo]                             = React.useState('');
    const [open, setOpen]                                 = React.useState(false);
    const [isCaptcha, setIsCaptcha]                       = React.useState(true);
    const [openCaptcha, setOpenCaptcha]                   = React.useState(false);
    const [schools, setSchools]                           = React.useState([]);
    const [school, setSchool]                             = React.useState('');

    // const handleSuccess = () => setIsCaptcha(true);
	// const handleFailure = () => setIsCaptcha(false);

    const setFirstNameC = name => {
        setFirstName(name)
        Constants.search.firstName = name
    }

    const setMiddleNameC = name => {
        setMiddleName(name)
        Constants.search.middleName = name
    }

    const setLastNameC = name => {
        setLastName(name)
        Constants.search.lastName= name
    }

    const setEpicNoC = number => {
        setEpicNo(number)
        Constants.search.epicNo = number
    }

    const setSchoolC = schoolname => {
        setSchool(schoolname.label)
        Constants.search.school = schoolname.label
    }

    const valid = () => {
        if(firstName === '' && middleName === '' && lastName === '' && epicNo === '' && school === '') {
            return false
        }

        if(firstName !== '' && firstName.length < 3) {
            return false
        } 
        
        if(middleName !== ''  && middleName.length < 3) {
         return false   
        }

        if(lastName !== ''  && lastName.length < 3) { 
            return false
        }

        if(epicNo !== ''  && epicNo.length < 3) { 
            return false
        }
        return true
    }
 
    const searchvoter = () => {
        if(valid()) {
            if(isCaptcha) {
                Constants.voters = []
                setTimeout(() => Constants.navigate('/list'), 50)
            } else {
                setOpen(false)
                setOpenCaptcha(true)
                setLoading(false)
            }
        } else {
            setLoading(false)
            setOpen(true)
            setOpenCaptcha(false)
        }
        
    }

    React.useEffect(() => {
        if(Constants?.selectedConstituency === undefined) {
            Constants.navigate('/')
        }
        setSelectedConstituency(Constants?.selectedConstituency)
        Constants.search = {};
        load()
    }, [])

    const load = () => {
        if(Constants?.searches?.filter(f => f.name === "school").length) {
            axios.get(`https://api-voter-search.webemps.com/schools.php?TokenNo=${Constants.selectedConstituency?.token}`)
                .then(data => {
                    data = data?.data?.length ? data?.data?.map(e => ({label: e.EnglishBoothAddress})) : []
                setSchools(data)
                }).catch(e => console.error(e))
        }
    }

    return (
        <>
            <Card >
            
                <CardHeader title={`${selectedConstituency?.number} - ${Constants.selectedLanguage === 'en' ? selectedConstituency?.name : selectedConstituency?.namemr}`} sx={{ textAlign: 'center' }} className='bg-brand' />

                <CardContent>
                    {
                        (Constants?.searches?.filter(f => f.name === "school").length) ? <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={schools}
                            sx={{ marginBottom: '17px' }}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label="School/Collage" />}
                            onChange={(e, v) => setSchoolC(v)}
                        /> : <></>
                    }
                    
                    <TextField
                        label={Constants.languages[Constants.selectedLanguage].search.lbl.firstName}
                        id="outlined-size-small-f-name"
                        defaultValue={Constants?.search?.firstName}
                        size="small"
                        fullWidth
                        value={firstName}
                        onChange={e => setFirstNameC(e.target.value)}
                    />
                    <TextField
                        sx={{mt: 2}}
                        label={Constants.languages[Constants.selectedLanguage].search.lbl.middleName}
                        id="outlined-size-small-m-name"
                        defaultValue={Constants?.search?.middleName}
                        size="small"
                        fullWidth
                        value={middleName}
                        onChange={e => setMiddleNameC(e.target.value)}
                    />
                    <TextField
                        sx={{mt: 2}}
                        label={Constants.languages[Constants.selectedLanguage].search.lbl.lastName}
                        id="outlined-size-small-l-name"
                        defaultValue={Constants?.search?.lastName}
                        size="small"
                        fullWidth
                        value={lastName}
                        onChange={e => setLastNameC(e.target.value)}
                    />
                    <Typography sx={{mt: 2, textAlign: 'center'}}>
                        {Constants.languages[Constants.selectedLanguage].search.lbl.or}
                    </Typography>
                    <TextField
                        sx={{mt: 2}}
                        label={Constants.languages[Constants.selectedLanguage].search.lbl.voterEpicNo}
                        id="outlined-size-small-voter-epic-no"
                        defaultValue={Constants?.Search?.epicNo}
                        size="small"
                        fullWidth
                        value={epicNo}
                        onChange={e => setEpicNoC(e.target.value)}
                    /> 
                    {/* <LoadCanvasTemplate /> */}
                    {/* <ReactCaptcha
                        charset='N'
                        length={3}
                        color='white'
                        bgColor='black'
                        reload={false}
                        // reloadText='Reload Captcha'
                        // reloadIcon={reloadIcon}
                        handleSuccess={handleSuccess}
                        handleFailure={handleFailure} 
                    /> */}
                </CardContent>


                <CardActions>
                    <LoadingButton 
                        className='bg-brand'
                        variant="contained" 
                        size="small"
                        loading={loading}
                        fullWidth
                        onClick={
                            () => {
                                setLoading(true)
                                Constants.navigate('/')
                            }
                        }
                    >
                        <span> {'<'} {Constants.languages[Constants.selectedLanguage].search.btn.back}</span>
                    </LoadingButton>

                    <LoadingButton 
                        className='bg-brand'
                        variant="contained" 
                        size="small"
                        loading={loading}
                        fullWidth
                        onClick={
                            () => {
                                setLoading(true)
                                searchvoter()
                            }
                        }
                    >
                        <span>{Constants.languages[Constants.selectedLanguage].search.btn.submit}</span>
                    </LoadingButton>
                </CardActions>
            </Card>
            <Snackbar
                open={open}
            >
                <Alert severity="error">Please check search parameters, parameters should be atleas 3 charactors.</Alert>
            </Snackbar>
            {/* <Snackbar
                open={openCaptcha}
            >
                <Alert severity="error">Please check captcha.</Alert>
            </Snackbar> */}
        </>
            
    )
}

export default Search;
