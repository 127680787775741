import React from "react";
import { useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Constants from './../utilities/Constants';
import { Divider } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import ICard from "../utilities/Icard";

const voters = Constants?.voters;

const Details = () => {

  const { id } = useParams();
  const [voter, setVoter] = React.useState();
  const [number, setNumber] = React.useState('');
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if(Constants?.selectedConstituency === undefined) {
        Constants.navigate('/')
    }

    setVoter(Constants.voters.filter(e => e.ID === id)[0]);
    setNumber(Constants.voters.filter(e => e.ID === id)[0]?.MobileNo)
  }, [id])

  const getMessage = () => {
    let msg
  if(Constants.whatsapp !== undefined) {
    msg = Constants.whatsapp[Constants.selectedLanguage]
    let replacers = msg.match(/{(.*)}/g) //msg.split(/[{}]/)
    replacers.map(e => 
      msg = msg.replace(e, voter[e.replace('{', '').replace('}', '')]) 
    )
  } else {
    msg = Constants.selectedLanguage === 'mr' ?
`${Constants.languages['mr'].details.lbl.constituency}: *${voter.BoothName}*
${Constants.languages['mr'].details.lbl.serialNo}: *${voter.SerialNo}*
${Constants.languages['mr'].details.lbl.name}: *${voter.FullName_Marathi ? voter.FullName_Marathi : voter.FullName}* 
${Constants.languages['mr'].list.lbl.listNo}: *${voter.PartNumber}*
${Constants.languages['mr'].details.lbl.idCardNo}: *${voter.Cardno}*

${Constants.languages['mr'].details.lbl.pollingAddress}: *${voter.PollingStnAddress}* 

${Constants.languages['mr'].list.lbl.school}: *${voter.EnglishBoothAddress}*

${Constants.details.site}

${Constants.languages['mr'].details.lbl.note}`
    :
`${Constants.languages['en'].details.lbl.constituency}: *${voter.BoothName}*
${Constants.languages['en'].details.lbl.serialNo}: *${voter.SerialNo}* 
${Constants.languages['en'].details.lbl.name}: *${voter.FullName}* 
${Constants.languages['en'].list.lbl.listNo}: *${voter.PartNumber}*
${Constants.languages['en'].details.lbl.idCardNo}: *${voter.Cardno}*

${Constants.languages['en'].details.lbl.pollingAddress}: *${voter.PollingStnAddress}*

${Constants.languages['en'].list.lbl.school}: *${voter.EnglishBoothAddress}*

${Constants.details.site}

${Constants.languages['en'].details.lbl.note}
                              `;
}
return msg
  }
//   Constants.selectedLanguage === 'mr' ?
// `${Constants.languages['mr'].details.lbl.constituency}: *${Constants.selectedConstituency?.number} - ${Constants.selectedConstituency?.namemr}*
// ${Constants.languages['mr'].details.lbl.serialNo}: *${voter.SerialNo}*
// ${Constants.languages['mr'].details.lbl.name}: *${voter.FullName_Marathi ? voter.FullName_Marathi : voter.FullName}* 
// ${Constants.languages['mr'].list.lbl.listNo}: *${voter.PartNumber}*
// ${Constants.languages['mr'].details.lbl.idCardNo}: *${voter.Cardno}*
// ${Constants.languages['mr'].details.lbl.address}: *${voter.BuildingName_marathi}* 

// ${Constants.languages['mr'].details.lbl.pollingAddress}: *${voter.PollingStnAddress}* 
  
// ${Constants.languages['mr'].details.lbl.thanks}
// ${Constants.details.mlamr}
// ${Constants.details.partymr}
// ${Constants.languages['mr'].details.lbl.symbol}${Constants.languages['mr'].details.lbl.symbol ? ': ' : ''} ${Constants.details.symbolmr ? '*' + Constants.details.symbolmr + '*' : ''}
// ${Constants.details.positionmr}
// ${Constants.details.candidatemr ? '*' + Constants.details.candidatemr + '*' : ''}

// ${Constants.details.site}

// ${Constants.languages['mr'].details.lbl.note}`
//     :
// `${Constants.languages['en'].details.lbl.constituency}: *${Constants.selectedConstituency?.number} - ${Constants.selectedConstituency?.name}*
// ${Constants.languages['en'].details.lbl.serialNo}: *${voter.SerialNo}* 
// ${Constants.languages['en'].details.lbl.name}: *${voter.FullName}* 
// ${Constants.languages['en'].list.lbl.listNo}: *${voter.PartNumber}*
// ${Constants.languages['en'].details.lbl.idCardNo}: *${voter.Cardno}*
// ${Constants.languages['en'].details.lbl.address}: *${voter.BuildingName}* 

// ${Constants.languages['en'].details.lbl.pollingAddress}: *${voter.PollingStnAddress}*

// ${Constants.languages['en'].list.lbl.school}: *${voter.EnglishBoothAddress}*

// ${Constants.languages['en'].details.lbl.thanks},
// ${Constants.details.mla}
// ${Constants.details.party}
// ${Constants.languages['en'].details.lbl.symbol}${Constants.languages['en'].details.lbl.symbol ? ': ' : ''} ${Constants.details.symbol ? '*' + Constants.details.symbol + '*': ''}
// ${Constants.details.position}
// ${Constants.details.candidate ? '*' + Constants.details.candidate + '*' : ''}

// ${Constants.details.site}

// ${Constants.languages['en'].details.lbl.note}
//                               `;

// `
// *${voter.lastName} ${voter.firstName} ${voter.middleName}  (${voter.age} ${voter.sex})* \n
// ${Constants.languages[Constants.selectedLanguage].details.lbl.constituency}: Constants.selectedConstituency?.name
// ${Constants.languages[Constants.selectedLanguage].details.lbl.voterNo}: ${voter.voterNo} 
// ${Constants.languages[Constants.selectedLanguage].details.lbl.listNo}: ${voter.listNo} 
// ${Constants.languages[Constants.selectedLanguage].details.lbl.idCardNo}: ${voter.VoterID} \n
// ${Constants.languages[Constants.selectedLanguage].details.lbl.address}: ${voter.votingCenter} \n
// ${Constants.languages[Constants.selectedLanguage].details.lbl.thanks}
// Mr, Abc XYZ 
// Party name 
// ${Constants.languages[Constants.selectedLanguage].details.lbl.symbol} - SYMBOL\n
// http://nominee.webemps.com\n
// ${Constants.languages[Constants.selectedLanguage].details.lbl.note}
                            // `;

  const sendWhatsapp = () => {
    
      let message = getMessage().replace('**', '').replace('**', '').replace('**', '').replace('**', '').replace('**', '').replace('**', '').replace('**', '')
      let url     = number !== null ? 
                    `https://wa.me/${number.replace(/[^\w\s]/gi, "").replace(/ /g, "")}?&text=${encodeURIComponent(message)}&app_absent=0` 
                    :
                    `https://wa.me/?&text=${encodeURIComponent(message)}&app_absent=0`;
      window.open(url);
    
  }

  const sendSMSapp = () => {
    let message = getMessage().replace('*', '').replace('*', '').replace('*', '').replace('*', '').replace('*', '').replace('*', '').replace('*', '').replace('*', '')
    let url     = `sms://${number.replace(/[^\w\s]/gi, "").replace(/ /g, "")}/?body=${encodeURI(message)}`
    window.open(url);
  }


  return (
    voter ? <> 
      <Card >
          <CardHeader title={
            Constants.languages[Constants.selectedLanguage].details.ttl.pageTitle 
          } sx={{ textAlign: 'center' }} className="bg-brand"/>
          <Divider className="bg-brand" />
          <CardContent>
           <ICard e={voter} />
           <Grid item xs={12}>
              <TextField
                  sx={{mt: 2}}
                  label={Constants.languages[Constants.selectedLanguage].details.lbl.mobileNo}
                  id="outlined-size-small-mobile-no"
                  defaultValue={number}
                  size="small"
                  fullWidth
                  value={number}
                  onChange={e => setNumber(e.target.value)}
              />
            </Grid>
          </CardContent>
          <CardActions>
                    <LoadingButton 
                        className='bg-brand'
                        variant="contained" 
                        size="small"
                        loading={loading}
                        fullWidth
                        onClick={
                            () => {
                              setLoading(true)
                              Constants.navigate('/list')
                              setTimeout(() => setLoading(false), 1000)
                            }
                        }
                    >
                        <span>
                          {'< '} {Constants.languages[Constants.selectedLanguage].details.btn.back}
                        </span>
                    </LoadingButton>
                    <LoadingButton 
                        className='bg-brand'
                        variant="contained" 
                        size="small"
                        loading={loading}
                        fullWidth
                        onClick={
                            () => {
                                setLoading(true)
                                sendWhatsapp()
                                setTimeout(() => setLoading(false), 1000)
                            }
                        }
                    >
                        <span>
                          {Constants.languages[Constants.selectedLanguage].details.btn.sendWhatsapp}
                        </span>
                    </LoadingButton>
                    <LoadingButton 
                        className='bg-brand'
                        variant="contained" 
                        size="small"
                        loading={loading}
                        fullWidth
                        onClick={
                            () => {
                              setLoading(true)
                              sendSMSapp()
                              setTimeout(() => setLoading(false), 1000)
                            }
                        }
                    >
                        <span>
                          {Constants.languages[Constants.selectedLanguage].details.btn.sendSms}
                        </span>
                    </LoadingButton>
          </CardActions>
      </Card>
    </> : 
    <>
      <Skeleton variant="rectangular" width={'100%'} height={450} animation="wave" />
    </>
  )

}
export default Details;
