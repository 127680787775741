


const Constants = {
    voters: [],
    selectedLanguage: 'mr',
    search: {},
    selectedVoter: {},
};

export default Constants;