import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import InfoIcon from '@mui/icons-material/Info';
import Constants from "../utilities/Constants";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const Layout = () => {

  const [lang, setLang] = React.useState()
  const [wait, setWait] = React.useState(true)
  const [help, setHelp] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  Constants.navigate = useNavigate();

  const handleChange = (e) =>{
    Constants.selectedLanguage = e.target.value
    setLang(e.target.value)
    Constants.navigate('/redirect')
  }

  React.useEffect(() => {
    if (window.location.protocol == 'https:') {  

     } else {
      setWait(false)
     }

     load()
  }, [])

  const load = async () => {
    var data = await axios.get(`https://api-voter-search.webemps.com/data.php?url=${window.location.host}`)
    data = data.data
    
    Constants.api = data.api
    Constants.constituencies = data.constituencies
    Constants.details = data.details
    Constants.languages = data.languages
    Constants.selectedLanguage = data.selectedLanguage
    Constants.searches = data?.searches
    Constants.sms = data?.sms
    Constants.whatsapp = data?.whatsapp
    setLoading(false)
  }

  const breadcrumbs =() => {
    let url = window.location.pathname;
    switch(url.split('/')[1]) {
      case '':
        return <Breadcrumbs aria-label="breadcrumb" >
                    <Typography color="text.primary" style={{ display: 'flex',
                                                              textAlign: 'center',
                                                              justifyContent: 'center',
                                                              alignItems: 'center',
                                                              fontSize: '20px'}}
                                                              className="text-brand"
                                                          >
                      <HomeIcon />
                      {Constants.languages[Constants.selectedLanguage].layout.bc.home}
                    </Typography>
                </Breadcrumbs>;
      case 'search':
        return <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" to="/" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <HomeIcon />
                      {/* {Constants.languages[Constants.selectedLanguage].layout.bc.home} */}
                    </Link>
                    <Typography color="text.primary" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <SearchIcon />
                      {Constants.languages[Constants.selectedLanguage].layout.bc.search}
                    </Typography>
                </Breadcrumbs>;
      case 'list':
        return <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" to="/" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <HomeIcon />
                      {/* {Constants.languages[Constants.selectedLanguage].layout.bc.home} */}
                    </Link>
                    <Link underline="hover" color="inherit" to="/search" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <SearchIcon />
                        <Typography color="text.primary" className="text-brand">
                          {/* {Constants.languages[Constants.selectedLanguage].layout.bc.search} */}
                        </Typography>
                    </Link>
                    <Typography color="text.primary" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <FormatListBulletedIcon />
                      {Constants.languages[Constants.selectedLanguage].layout.bc.list}
                    </Typography>
                </Breadcrumbs>;
      case 'details':
        return <Breadcrumbs aria-label="breadcrumb" >
                    <Link underline="hover" color="inherit" to="/" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <HomeIcon />
                      {/* {Constants.languages[Constants.selectedLanguage].layout.bc.home} */}
                    </Link>
                    <Link underline="hover" color="inherit" to="/search" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <SearchIcon />
                        <Typography color="text.primary" className="text-brand">
                          {/* {Constants.languages[Constants.selectedLanguage].layout.bc.search} */}
                        </Typography>
                    </Link>
                    <Link underline="hover" color="inherit" to="/list" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <FormatListBulletedIcon />
                      <Typography color="text.primary" className="text-brand">
                        {/* {Constants.languages[Constants.selectedLanguage].layout.bc.list} */}
                      </Typography>
                    </Link>
                    <Typography color="text.primary" style={{    display: 'flex',
                                                          textAlign: 'center',
                                                          justifyContent: 'center',
                                                          alignItems: 'center',
                                                          fontSize: '20px'}}
                                                          className="text-brand"
                                                          >
                      <InfoIcon />
                      {Constants.languages[Constants.selectedLanguage].layout.bc.details}
                    </Typography>
                </Breadcrumbs>;
        default:
          return <></>
    }
                  
  }

  return loading ? <CircularProgress style={{margin: "0 auto", display: "block"}}/> : (
    <>
    
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" className="bg-brand">
            <Toolbar>
              <Typography 
                variant="h3"
                sx={{ textAlign: 'center' }}
              > 
                {/* <Link to={'/'}>
                    <HomeIcon />
                  </Link> */}
              </Typography>

              <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }} display="flex" justifyContent="flex-end">
               {Constants.languages[Constants.selectedLanguage].layout.ttl.siteTitle}  
              </Typography> 
              <Typography variant="h7" component="div" sx={{ flexGrow: 1 }} display="flex" justifyContent="flex-end">
                <FormControl size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Constants.selectedLanguage}
                    onChange={handleChange}
                  >
                    <MenuItem value={'en'}>English</MenuItem>
                    <MenuItem value={'mr'}>Marathi</MenuItem>
                  </Select>
                </FormControl>
              </Typography> 
            </Toolbar>
          </AppBar>
          <Container maxWidth="sm" sx={{pb:7}}>
            <div style={{display: 'flex', paddingTop: '15px'}}>
            {
              breadcrumbs() 
            }
            <Typography 
              variant="h7" 
              component="div" 
              sx={{ flexGrow: 1, textAlign: 'center', cursor: 'pointer' }} 
              display="flex" 
              justifyContent="flex-end"
              className="text-brand"
              onClick={() => setHelp(!help)}
            >
               {help ? Constants.languages[Constants.selectedLanguage].layout.help.close : Constants.languages[Constants.selectedLanguage].layout.help.help}  
              </Typography> 
            </div>
            <Divider sx={{mb: 2}} />
            <Paper sx={{mt:3}}>
                <img src={Constants.details.banner} width={'100%'} alt="banner" />
            </Paper>
              { help ? <section style={{width:"100%", padding: '20px 10px', zIndex: 1}}>
                <p>
                  {Constants.languages[Constants.selectedLanguage].layout.lbl.stepsTitle}
                    :
                </p>
                <ol>
                    <li>
                      {Constants.languages[Constants.selectedLanguage].layout.lbl.step0}
                    </li>
                    <li>
                      {Constants.languages[Constants.selectedLanguage].layout.lbl.step1}
                    </li>
                    <li>
                      {Constants.languages[Constants.selectedLanguage].layout.lbl.step2} 
                    </li>
                    <li>
                      {Constants.languages[Constants.selectedLanguage].layout.lbl.step3}
                    </li>
                    <li>
                      {Constants.languages[Constants.selectedLanguage].layout.lbl.step4}
                    </li>
                    <li>
                      {Constants.languages[Constants.selectedLanguage].layout.lbl.step5}
                    </li>
                </ol>
            </section> : <Outlet />}
            </Container>
            <footer style={{ width:"100%", padding: '20px 0', zIndex: 1}} className="bg-brand">
              <center>
                {/* Powered by <a href="https://www.i-vote.in/" target="_blank" rel="noreferrer">I-Vote</a> */}
              </center>
            </footer>
            
        </Box>
    </>
  )
};

export default Layout;