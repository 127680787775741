import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Constants from './Constants';
import CardHeader from '@mui/material/CardHeader';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';

const ICard = ({e}) => {

    return (<>
        <ListItem alignItems="flex-start" key={e.id}>
                <Link to={`/details/${e.ID}`} style={{ textDecoration: 'none' }}>
                    <ListItemText
                    primary={
                        <>
                            <h2 className='text-brand' style={{paddingBottom: 0, marginBottom: 0, textAlign: 'center'}}>
                                {Constants.selectedLanguage == 'en' ? e.FullName : (e.FullName_Marathi ? e.FullName_Marathi : e.FullName)}
                                {`${' '} (${e.Age} ${Constants.selectedLanguage == 'en' ? e.Sex : (e.Sex == 'F' ? 'स्त्री' : 'पु')})`}                                    
                            </h2>
                            <h4 className='text-brand' style={{textAlign: 'center', paddingTop: 0, marginTop: 0}}>
                                {e.MobileNo}
                            </h4>
                        </>
                    }
                    secondary={
                        <>
                        <Grid container spacing={2} sx={{width: '100%'}}>
                            <Grid item xs={12}>
                                <Typography>
                                {Constants.languages[Constants.selectedLanguage].list.lbl.constituency}
                                : {' '}
                                {/* {Constants.selectedLanguage == 'en' ? Constants.selectedConstituency?.name : Constants.selectedConstituency?.namemr} */}
                                {e?.BoothName}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                {Constants.languages[Constants.selectedLanguage].list.lbl.voterNo}
                                : {' '}
                                {e.SerialINpart}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                {Constants.languages[Constants.selectedLanguage].list.lbl.listNo}
                                : {' '}
                                {e.PartNumber}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                {Constants.languages[Constants.selectedLanguage].list.lbl.idCardNo}
                                : {e.Cardno}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                {Constants.languages[Constants.selectedLanguage].list.lbl.address}
                                : {Constants.selectedLanguage == 'en' ? e.BuildingName : (e.BuildingName_marathi ? e.BuildingName_marathi : e.BuildingName)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                {Constants.languages[Constants.selectedLanguage].list.lbl.pollingAddress}
                                : {Constants.selectedLanguage == 'en' ? e.PollingStnAddress : e.PollingStnAddress}
                                </Typography>
                            </Grid>
                            {Constants.languages[Constants.selectedLanguage].list.lbl.school && Constants.languages[Constants.selectedLanguage].list.lbl.school != "" ?
                            <Grid item xs={12}>
                                <Typography sx={{ fontWeight: 'bold' }}>
                                {Constants.languages[Constants.selectedLanguage].list.lbl.school}
                                : {Constants.selectedLanguage == 'en' ? e.EnglishBoothAddress : e.EnglishBoothAddress}
                                </Typography>
                            </Grid> : <></>
                            }
                            
                            
                        </Grid>
                        </>
                    }
                    />
                </Link>
            </ListItem>
    </>
                    
)}

export default ICard;
