import * as React from 'react';
import { useHistory } from 'react-router-dom'; 
import Constants from '../utilities/Constants';


const Redirect = () => {

    

    React.useEffect(() => {
        window.history.back();
    }, [])

    return (
        <>
            
        </>
            
    )
}

export default Redirect;
