import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Constants from '../utilities/Constants';
import Skeleton from '@mui/material/Skeleton';


const Welcome = () => {

  
    const [loading, setLoading] = React.useState(true);
    
    React.useEffect(() => {
        setLoading(false)
    }, [])

    return (
        <>
            <Card >
            
                <CardHeader title={
                    Constants.languages[Constants.selectedLanguage].home.ttl.pageTitle
                } sx={{ textAlign: 'center' }} className='bg-brand' />

                <CardContent>

                { loading ? [1,2,3,4,5,6,7].map(e => <>
                            <Skeleton variant="rectangular" width={'100%'} height={100} animation="wave" />
                            <Divider sx={{margin: '10px 0'}} />
                        </>) :
                    <p>
                        {Constants.languages[Constants.selectedLanguage].details.lbl.note}
                    </p>
                }
                </CardContent>

            </Card>
        </>
            
    )
}

export default Welcome;
